<template>
  <div>
    <!-- <v-app-bar flat dense app style="top: 80px"> -->
    <v-app-bar flat dense app style="top: 50px" :color="appTitleColor">
      <v-app-bar-nav-icon
        @click.stop="dataDrawer.show = !dataDrawer.show"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        :class="
          $options.filters.isWhiteText(publishColor(isPublish))
            ? 'white--text'
            : 'black--text'
        "
      >
        <FavoriteButton :siteShot="siteShot" />

        <span v-if="siteShot.url" class="text-subtitle-1 col-2 text-truncate">
          <WCopyToClipBoard :value="siteShot.url" />

          <a :href="siteShot.url" target="_blank" class="text-decoration-none"
            >{{ siteShot.url | lessStr(60) }}
          </a>
        </span>
      </v-toolbar-title>
      <v-spacer />

      <template v-if="isAuthor">
        <v-switch
          class="mt-5"
          v-model="publishType"
          :loading="publishTypeEditor.loading"
          :disabled="publishTypeEditor.loading"
          :true-value="1 << 1"
          :false-value="1"
          @change="onCommitPublishType(publishType)"
        >
          <template v-slot:label>
            <span class="text-caption">公開</span>
          </template>
        </v-switch>

        <!-- <validation-observer v-slot="publishTypeObserver"> -->
        <!-- <validation-observer>
          <v-menu offset-y v-model="publishTypeEditor.show">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                @click="
                  {
                    publishTypeEditor.publishType = publishType;
                    publishTypeEditor.error.errors = {
                      publishType: [],
                    };
                  }
                "
                outlined
              >
                {{ isPublish ? "公開" : "非公開" }}
              </v-chip>
            </template>
            <v-card :loading="publishTypeEditor.loading" flat>
              <v-card-text>
                <validation-provider name="label" rules="max:3">
                  <v-chip-group v-model="publishTypeEditor.publishType">
                    <v-chip
                      :color="publishColor(false)"
                      :outlined="!(publishTypeEditor.publishType & 1) != 0"
                      :value="1"
                      @click="onCommitPublishType(1)"
                    >
                      非公開
                    </v-chip>
                    <v-chip
                      :color="publishColor(true)"
                      :outlined="
                        !(publishTypeEditor.publishType & (1 << 1)) != 0
                      "
                      :value="1 << 1"
                      @click="onCommitPublishType(1 << 1)"
                    >
                      公開
                    </v-chip>
                  </v-chip-group>
                </validation-provider>
              </v-card-text>
          
            </v-card>
          </v-menu>
        </validation-observer> -->

        <v-btn icon @click.stop="authorDrawer.show = !authorDrawer.show">
          <v-icon v-text="`mdi-dots-vertical`" />
        </v-btn>
      </template>
    </v-app-bar>
    <div class="d-flex flex-row-reverse text-caption">
      <span>
        <v-icon v-text="`mdi-clock-outline`" small />
        {{ siteShot.date | dateTime }}
      </span>
    </div>
    <v-row>
      <v-col cols="12" :md="7">
        <v-sheet>
          <!-- <v-container style="height: 1000px"> -->

          <v-card flat>
            <!-- <v-avatar :color="color" />
          palette:{{ palette }}
          <v-avatar
            v-for="(color, index) of palette"
            :key="index"
            :color="color"
          /> -->

            <v-carousel height="380" hide-delimiters>
              <v-carousel-item
                v-for="(item, i) in siteShot.screenShots.filter((a) =>
                  a.contentType.startsWith('image')
                )"
                :key="i"
                :src="item.src"
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
                <!-- 画像に情報表示する
                <v-app-bar color="transparent" flat>
                  <v-spacer />
                  <v-btn
                    icon
                    @click="
                      liteBox.src = item.src;
                      liteBox.fileName = item.fileName;
                      liteBox.show = true;
                    "
                  >
                    <v-icon v-text="'mdi-code-brackets'" />
                  </v-btn>
                </v-app-bar>
                <v-footer absolute color="transparent">
                  <span>{{ item.url }}</span>
                </v-footer> -->
              </v-carousel-item>
            </v-carousel>
            <!-- <v-img
            height="250"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
          >
            <v-app-bar color="transparent" flat>
              <v-spacer />
              <v-btn icon @click="liteBox.show = true">
                <v-icon color="white" v-text="'mdi-code-brackets'" />
              </v-btn>
            </v-app-bar>
            <v-footer absolute color="transparent"> </v-footer>
          </v-img> -->
          </v-card>

          <v-divider class="my-4" />

          <v-subheader>
            <v-icon v-text="`mdi-palette`" />
            <span>dominantColors</span>
            <span class="mx-2 text-caption">
              {{ `(${siteShot.color.dominantColors.length})` }}
            </span>
          </v-subheader>
          <v-list-item>
            <v-list-item-content>
              <div>
                <v-avatar
                  v-for="(color, i) of siteShot.color.dominantColors.slice(
                    0,
                    10
                  )"
                  :key="i"
                  :color="color.color"
                />
              </div>
            </v-list-item-content>
            <v-list-item-action v-if="siteShot.color.dominantColors.length > 0">
              <v-list-item-action-text
                v-if="siteShot.color.dominantColors.length - 10 > 0"
                class="mx-2 text-caption"
              >
                {{ `more ${siteShot.color.dominantColors.length - 10}.` }}
              </v-list-item-action-text>
              <a @click="dominantColorDrawer.show = true">
                <v-icon v-text="'mdi-chevron-right'" /> </a
            ></v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-subheader>
            <v-icon v-text="`mdi-format-color-fill`" />
            <span>colors</span>
            <span class="mx-2 text-caption">
              {{ `(${siteShot.color.colorGroups.length})` }}
            </span>
          </v-subheader>
          <v-list-item>
            <v-list-item-content>
              <div>
                <v-avatar
                  v-for="(except, i) of siteShot.color.colorGroups
                    .map((a) => ({
                      color: a.color,
                      value: a.count,
                    }))
                    .slice(0, 10)"
                  :key="i"
                  :color="except.color"
                />
              </div>
            </v-list-item-content>
            <v-list-item-action v-if="siteShot.color.colorGroups.length > 0">
              <v-list-item-action-text
                v-if="siteShot.color.colorGroups.length - 10 > 0"
                class="mx-2 text-caption"
              >
                {{ `more ${siteShot.color.colorGroups.length - 10}.` }}
              </v-list-item-action-text>
              <a @click="colorDrawer.show = true">
                <v-icon v-text="'mdi-chevron-right'" /> </a
            ></v-list-item-action>
          </v-list-item>
          <v-divider />

          <v-subheader>
            <span>
              <v-icon v-text="`mdi-face-recognition`" />

              <span>faces</span>
            </span>
            <span class="mx-2 text-caption">
              {{ `(${siteShot.face.faces.length})` }}
            </span>
          </v-subheader>
          <v-list-item>
            <v-list-item-content>
              <!-- <v-row>
            <v-col
              v-for="(instance, j) of instances.slice(0, 5)"
              :key="`${j}`"
              cols="4"
            >
              <v-card class="text-center" outlined>
                <v-sheet class="pa-4">
                  <v-avatar>
                    <v-img :src="instance.src"> </v-img>
                  </v-avatar>
                </v-sheet>

                <v-card-title class="pa-2 text-caption text-none">
                </v-card-title>
              </v-card>
            </v-col>
          </v-row> -->

              <v-row>
                <v-col
                  v-for="(face, i) of siteShot.face.faces"
                  :key="i"
                  cols="3"
                >
                  <v-card class="text-center" outlined>
                    <v-sheet class="pa-4">
                      <v-avatar color="grey">
                        <v-img :src="face.src"> </v-img>
                      </v-avatar>
                    </v-sheet>

                    <v-card-title class="pa-2 text-caption text-none">
                      <v-spacer />
                      <span>{{ face.name ? face.name : "-" }}</span>
                      <v-spacer />
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-action v-if="siteShot.face.faces.length > 0">
              <v-list-item-action-text
                v-if="siteShot.face.faces.length - 5 > 0"
                class="mx-2 text-caption"
                >{{ `more ${siteShot.face.faces.length - 5}.` }}
              </v-list-item-action-text>
              <a @click="faceDrawer.show = true">
                <v-icon v-text="'mdi-chevron-right'" /> </a
            ></v-list-item-action>
          </v-list-item>

          <v-divider />

          <v-subheader>
            <v-icon v-text="`mdi-format-list-bulleted`" />
            <span>entities</span>
            <span class="mx-2 text-caption">
              {{ `(${siteShot.entities.length})` }}
            </span>
          </v-subheader>

          <!-- <v-expansion-panels>
        <v-expansion-panel
          v-for="(data, i) in [
            { value: 'person', label: 'person', icon: 'mdi-account' },
            {
              value: 'organization',
              label: 'organization',
              icon: 'mdi-domain',
            },
            {
              value: 'commercialItem',
              label: 'commercialItem',
              icon: 'mdi-bag-checked',
            },
            { value: 'title', label: 'title', icon: 'mdi-format-text' },
            { value: 'location', label: 'location', icon: 'mdi-map-marker' },
            { value: 'other', label: 'other', icon: 'mdi-bookmark-outline' },
          ]"
          :key="i"
        >
          <v-expansion-panel-header>
            <span>
              <v-icon v-text="data.icon" />
              <span>{{ data.label }}</span>
              <span class="mx-2 text-caption">
                {{ `(${siteShot.entityGroup[data.value].length})` }}
              </span>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item>
              <v-list-item-content>
                <v-card outlined>
                  <wordcloud
                    :data="siteShot.entityGroup[data.value]"
                    nameKey="text"
                    valueKey="count"
                    spiral="rectangular"
                    :rotate="{ from: -0, to: 0, numOfOrientation: 1 }"
                    :fontSize="[30, 80]"
                    :color="wordCloudColor"
                    :showTooltip="true"
                    :wordClick="() => {}"
                  />
                </v-card>
              </v-list-item-content>
              <v-list-item-action v-if="siteShot.entities.length > 0">
                <a
                  @click="
                    entityDrawer.label = data.label;
                    entityDrawer.groupName = data.value;
                    entityDrawer.icon = data.icon;
                    entityDrawer.show = true;
                  "
                >
                  <v-icon v-text="'mdi-chevron-right'" /> </a
              ></v-list-item-action>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->

          <div class="ml-2" v-for="(data, i) in entityGroups" :key="i">
            <v-list-item dense>
              <v-list-item-content
                @click="data.show = data = !data.show"
                style="cursor: pointer"
              >
                <!-- <v-btn @click="data.show = $set(data, 'show', !data.show)"> -->

                <v-subheader>
                  <v-icon
                    v-text="data.show ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                  />
                  <v-icon v-text="data.icon" />
                  <span>{{ data.label }}</span>
                  <span class="mx-2 text-caption">
                    {{ `(${siteShot.entityGroup[data.value].length})` }}
                  </span>
                </v-subheader>
              </v-list-item-content>
              <v-list-item-action v-if="siteShot.entities.length > 0">
                <a
                  @click="
                    entityDrawer.label = data.label;
                    entityDrawer.groupName = data.value;
                    entityDrawer.icon = data.icon;
                    entityDrawer.show = true;
                  "
                >
                  <v-icon v-text="'mdi-chevron-right'" />
                </a>
              </v-list-item-action>
            </v-list-item>

            <v-slide-y-transition mode="in-out">
              <v-card v-if="data.show" outlined>
                <wordcloud
                  :data="siteShot.entityGroup[data.value]"
                  nameKey="text"
                  valueKey="count"
                  spiral="rectangular"
                  :rotate="{ from: -0, to: 0, numOfOrientation: 1 }"
                  :fontSize="[30, 80]"
                  :color="wordCloudColor"
                  :showTooltip="true"
                  :wordClick="() => {}"
                />
              </v-card>
            </v-slide-y-transition>
          </div>

          <v-divider />

          <v-subheader>
            <v-icon v-text="`mdi-semantic-web`" />
            <span>content</span>
          </v-subheader>

          <div
            class="ml-2"
            v-for="(data, i) of contentGroups"
            :key="`content_${i}`"
          >
            <v-list-item dense>
              <v-list-item-content
                @click="data.show = data = !data.show"
                style="cursor: pointer"
              >
                <!-- <v-btn @click="data.show = $set(data, 'show', !data.show)"> -->

                <v-subheader>
                  <v-icon
                    v-text="data.show ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                  />
                  <span>{{ data.label }}</span>
                  <span class="mx-2 text-caption">
                    {{ `(${siteShot.content[data.label].length})` }}
                  </span>
                </v-subheader>
              </v-list-item-content>
              <v-list-item-action
                v-if="siteShot.content[data.value].length > 0"
              >
                <a
                  @click="
                    contentDrawer.label = data.label;
                    contentDrawer.groupName = data.value;
                    contentDrawer.icon = data.icon;
                    contentDrawer.show = true;
                  "
                >
                  <v-icon v-text="'mdi-chevron-right'" /> </a
              ></v-list-item-action>
            </v-list-item>

            <v-slide-y-transition mode="in-out">
              <v-card v-if="data.show" outlined>
                <wordcloud
                  :data="siteShot.contentGroup[data.value]"
                  nameKey="text"
                  valueKey="count"
                  spiral="rectangular"
                  :rotate="{ from: -0, to: 0, numOfOrientation: 1 }"
                  :fontSize="[30, 80]"
                  :color="wordCloudColor"
                  :showTooltip="true"
                  :wordClick="() => {}"
                />
              </v-card>
            </v-slide-y-transition>
          </div>

          <v-list-item>
            <v-list-item-content>
              <v-subheader>
                <v-icon v-text="`mdi-file-image`" />
                <span>images</span>
                <span class="mx-2 text-caption">
                  {{ `(${siteShot.content.images.length})` }}
                </span>
              </v-subheader>
            </v-list-item-content>
            <v-list-item-action v-if="siteShot.content.images.length > 0">
              <v-list-item-action-text
                v-if="siteShot.content.images.length > 0"
                class="mx-2 text-caption"
              >
              </v-list-item-action-text>
              <a @click="imageDrawer.show = true">
                <v-icon v-text="'mdi-chevron-right'" />
              </a>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-subheader>
                <v-icon v-text="`mdi-link-box-outline`" />
                external links

                <span
                  >({{
                    siteShot.content.links.filter((a) =>
                      a.href.startsWith("http")
                    ).length
                  }})
                </span>
              </v-subheader>
            </v-list-item-content>
            <div
              v-if="
                siteShot.content.links.filter((a) => a.href.startsWith('http'))
                  .length > 0
              "
            >
              <a
                @click="
                  linkDrawer.tab = 0;
                  linkDrawer.show = true;
                "
              >
                <v-icon v-text="'mdi-chevron-right'" />
              </a>
            </div>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-subheader>
                <span><v-icon v-text="`mdi-link-box`" />internal links</span>
                <span>
                  ({{
                    siteShot.content.links.filter(
                      (a) => !a.href.startsWith("http")
                    ).length
                  }})
                </span>
              </v-subheader>
            </v-list-item-content>
            <v-list-item-action
              v-if="
                siteShot.content.links.filter((a) => !a.href.startsWith('http'))
                  .length > 0
              "
            >
              <a
                @click="
                  linkDrawer.tab = 1;
                  linkDrawer.show = true;
                "
              >
                <v-icon v-text="'mdi-chevron-right'" />
              </a>
            </v-list-item-action>
          </v-list-item>

          <v-divider />

          <WAdsense />

          <template v-if="siteShot.history.queries.length > 0">
            <v-subheader>
              <v-icon v-text="`mdi-file-image`" />
              <span>current site shots</span>
              <span class="mx-2 text-caption">
                {{ `(${siteShot.history.count})` }}
              </span>
            </v-subheader>

            <v-list class="ml-2" dense>
              <div class="mx-2 font-weight-bold">host: {{ siteShot.host }}</div>

              <v-list-item
                v-for="(query, index) of siteShot.history.queries"
                :key="`history_${index}`"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <WCopyToClipBoard :value="query.url" />
                        <a
                          class="text-decoration-none"
                          :href="query.url"
                          target="_blakn"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-chip
                            v-if="query.scheme == 'http'"
                            outlined
                            class="mr-2"
                            small
                            >http</v-chip
                          >
                          <span>{{ query.query ? query.query : "-" }}</span>
                        </a>
                      </template>
                      <span>{{ query.url }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle class="blue-grey--text text--lighten-2">
              <WCopyToClipBoard :value="query.url" />
              <a
                class="text-decoration-none"
                :href="query.url"
                target="_blakn"
                >{{ query.url }}</a
              >
            </v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-action>
                  <span class="text-caption"> {{ query.count }}</span>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>

          <v-navigation-drawer
            v-model="dataDrawer.show"
            width="300"
            app
            :style="{ top: merginHeight }"
          >
            <v-sheet class="overflow-y-auto" :max-height="innerHeight">
              <ShotData
                :show="dataDrawer.show"
                :siteShot="siteShot"
                @close="dataDrawer.show = false"
              />
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="authorDrawer.show"
            right
            width="400"
            app
            :style="{ top: merginHeight }"
          >
            <v-sheet class="overflow-y-auto" :max-height="innerHeight">
              <Author
                v-if="secretCode"
                :show="authorDrawer.show"
                :siteShot="siteShot"
                :secretCode="secretCode"
                :securityCode="securityCode"
                @close="authorDrawer.show = false"
                @change="onChange"
              />
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="dominantColorDrawer.show"
            right
            temporary
            width="300"
            app
            :style="{ top: merginHeight }"
          >
            <!-- 読み込み速度改善のためv-ifをつける -->
            <Color
              v-if="dominantColorDrawer.show"
              :colorDatas="
                siteShot.color.dominantColors.map((a) => ({
                  color: a.color,
                  value: a.population,
                }))
              "
              @close="dominantColorDrawer.show = false"
            />
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="colorDrawer.show"
            right
            temporary
            width="300"
            app
            :style="{ top: merginHeight }"
          >
            <!-- 読み込み速度改善のためv-ifをつける -->
            <Color
              v-if="colorDrawer.show"
              :colorDatas="
                siteShot.color.colorGroups.map((a) => ({
                  color: a.color,
                  value: a.count,
                }))
              "
              @close="colorDrawer.show = false"
            />
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="faceDrawer.show"
            right
            temporary
            width="350"
            app
            :style="{ top: merginHeight }"
          >
            <Face
              :shot="faceDrawer.show"
              :siteShot="siteShot"
              @close="faceDrawer.show = false"
            />
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="imageDrawer.show"
            right
            temporary
            width="450"
            app
            :style="{ top: merginHeight }"
          >
            <Image2
              :shot="imageDrawer.show"
              :siteShot="siteShot"
              @close="imageDrawer.show = false"
            />
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="contentDrawer.show"
            right
            temporary
            width="350"
            app
            :style="{ top: merginHeight }"
          >
            <Content
              :shot="contentDrawer.show"
              :siteShot="siteShot"
              :label="contentDrawer.label"
              :groupName="contentDrawer.groupName"
              @close="contentDrawer.show = false"
            />
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="entityDrawer.show"
            right
            temporary
            width="350"
            app
            :style="{ top: merginHeight }"
          >
            <Entity
              :shot="entityDrawer.show"
              :siteShot="siteShot"
              :label="entityDrawer.label"
              :groupName="entityDrawer.groupName"
              :icon="entityDrawer.icon"
              @close="entityDrawer.show = false"
            />
          </v-navigation-drawer>

          <v-navigation-drawer
            v-model="linkDrawer.show"
            right
            temporary
            width="450"
            app
            :style="{ top: merginHeight }"
          >
            <Link
              :show="linkDrawer.show"
              :tab.sync="linkDrawer.tab"
              :siteShot="siteShot"
              @close="linkDrawer.show = false"
            />
          </v-navigation-drawer>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="5">
        <WAdsense />

        <v-subheader>
          <v-icon v-text="`mdi-camera-outline`" />

          <span>screen shots</span>
        </v-subheader>
        <v-list color="transparent">
          <v-list-item
            v-for="(screenshot, index) of siteShot.screenShots"
            :key="index"
          >
            <v-list-item-avatar tile>
              <v-icon
                v-if="screenshot.contentType == 'application/pdf'"
                v-text="'mdi-file-pdf-box'"
                x-large
              />
              <v-icon
                v-else-if="screenshot.contentType.startsWith('text')"
                v-text="'mdi-file-code'"
                x-large
              />
              <!-- 表示画像はサムネイルから取得する -->
              <v-img v-else :src="screenshot.thumbnail" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2">
                <span> {{ screenshot.fileName }} </span>
                <span>({{ screenshot.contentType }})</span>
              </v-list-item-title>
              <!-- 保存していなければ表示しない -->
              <div v-if="(siteShot.publishType & PublishTypes.Public) != 0">
                <a
                  class="text-caption text-none"
                  :href="screenshot.url"
                  target="_blank"
                  :style="{ textDecoration: 'none' }"
                >
                  {{ screenshot.url }}
                </a>
                <WCopyToClipBoard :value="screenshot.url" />
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>
                <span>{{ screenshot.fileSize | fileSize }}</span>
              </v-list-item-action-text>

              <WFileDownload
                :src="screenshot.src"
                :fileName="screenshot.fileName"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-if="hasAuthorsContent">
          <v-subheader>
            <v-icon v-text="`mdi-creation`" />

            <span>screen shot author's</span>
          </v-subheader>
          <v-card outlined>
            <!-- <v-list-item v-if="(author.publishType & PublishTypes.Public) != 0"> -->

            <v-list-item>
              <v-list-item-avatar color="grey">
                <v-img :src="author.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span>{{ author.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>{{ author.link }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-card-text>
              <div
                v-if="Array.isArray(setting.tags) && setting.tags.length > 0"
              >
                <v-chip
                  class="ma-1"
                  v-for="(tag, i) of uniqArray(setting.tags)"
                  :key="i"
                  label
                  outlined
                  small
                >
                  {{ tag }}
                </v-chip>
              </div>
              <div
                v-if="setting.description != null"
                v-html="$options.filters.md(setting.description)"
              />
              <div
                v-if="
                  !setting.rate.disabled &&
                  setting.rate.label &&
                  setting.rate.ratings.length > 0
                "
              >
                <v-sheet
                  v-if="setting.rate.ratings.length >= 3"
                  class="text-center"
                >
                  <RadarChart
                    :chart-data="
                      getDataCollection(
                        setting.rate.ratings,
                        setting.rate.label
                      )
                    "
                    :options="graphOptions"
                  ></RadarChart>
                </v-sheet>

                <div v-else>
                  <span class="text-caption">{{ setting.rate.label }}</span>
                  <v-list-item
                    v-for="(rate, index) of setting.rate.ratings"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ rate.label }}</v-list-item-title>
                    </v-list-item-content>

                    <v-rating
                      :value="rate.value"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="14"
                    ></v-rating>

                    <v-list-item-action class="text-left">
                      <span class="text-caption grey--text">
                        {{ rate.value }}
                      </span>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <!-- <Disqus :identifier="siteShot.shortCode" /> -->
        <!-- <Disqus v-if="siteShot != null" /> -->
        <!-- <Disqus :identifier="siteShot.shortCode" /> -->
        <XDisqus :identifier="siteShot.shortCode" />

        <WAdsense />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RadarChart from "@/components/charts/RadarChart";

import Author from "./authors/Author";
import ShotData from "./shotDatas/ShotData";
import Color from "./colors/Color";
import Face from "./faces/Face";
import Image2 from "./images/Image";
import Link from "./links/Link";

import Content from "./contents/Content";
import Entity from "./entities/Entity";

import FavoriteButton from "@/components/assets/storages/favorites/Button";

import { PublishTypes } from "@/types";

import { UniqArray } from "@/util/array";

const RadarChartConfig = {
  dataset: {
    backgroundColor: "rgba(187, 200, 230, .5)", // 淡藤色
    borderWidth: 1,
    clip: 5,
    label: "評価",
  },
};

export default {
  name: "Shot",
  components: {
    RadarChart,
    Author,
    ShotData,
    Color,
    Face,
    Image2,
    Link,
    Content,
    Entity,

    FavoriteButton,
  },

  props: {
    siteShot: {
      type: Object,
      required: true,
    },
    secretCode: {
      type: String,
    },
    securityCode: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    PublishTypes() {
      return PublishTypes;
    },
    innerHeight() {
      return window.innerHeight - 50;
    },
    shotCode() {
      return this.$route.params.shotCode;
    },

    // instances() {
    //   return this.siteShot.recognition.recognitions.flatMap((a) => a.instances);
    // },

    // dominantColorDatas() {
    //   let excepts = [
    //     //   "#ffffff",
    //     //  "#0e0e0e"
    //   ];
    //   return this.siteShot.dominantColors.filter(
    //     (a) => !excepts.includes(a.color)
    //   );
    // },

    hasAuthorsContent() {
      if (this.author.avatar != null && this.author.avatar.length > 0)
        return true;

      if (
        this.setting.description != null &&
        this.setting.description.length > 0
      )
        return true;
      if (this.setting.tagValue != null && this.setting.tagValue.length > 0)
        return true;
      if (this.setting.rate.label != null && this.setting.rate.label.length > 0)
        return true;
      if (
        Array.isArray(this.setting.rate.ratings) &&
        this.setting.rate.ratings.length > 0
      )
        return true;
      return false;
    },

    dominantColorDataPieCollection() {
      let data = {
        labels: [],
        datasets: [
          {
            label: "",
            fill: false,
            lineTension: 0,
            borderWidth: 1,
            backgroundColor: [],
            data: [],
          },
        ],
      };
      //
      for (let i = 0; i < this.siteShot.color.dominantColors.length; i++) {
        let colorData = this.siteShot.dominantColors[i];
        data.labels.push(colorData.color);
        data.datasets[0].data.push(colorData.population);
        data.datasets[0].backgroundColor.push(colorData.color);
      }
      return data;
    },

    // https://observablehq.com/@d3/color-schemes
    wordCloudColor() {
      return ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"];
    },

    graphOptions() {
      let options = {
        // width , heightを有効にするため
        // responsive: false,
        scale: {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 5,
            stepSize: 1.0,
            // callback: function (value, index, values) {
            callback: function (value) {
              // return  value +  '単位'
              return value;
            },
          },
        },
      };
      return options;
    },

    isAuthor() {
      // 管理者か？
      return this.siteShot.isAuthor;
    },

    isPublish() {
      return (this.publishType & PublishTypes.Public) != 0;
    },
    merginHeight() {
      return "50px";
    },
    appTitleColor() {
      if (this.isAuthor) return this.publishColor(this.isPublish);
      return this.isPublish ? "" : "#FFCDD2";
    },
  },

  data: () => ({
    initializing: true,

    // ----- リアクティブに変更させたい部分を別途定義
    publishDate: null,
    publishType: PublishTypes.Private,

    publishTypeEditor: {
      loading: false,
      show: false,
      publishType: PublishTypes.Private,
      publishDate: null,

      error: {
        message: null,
        errors: { publishType: [] },
      },
    },

    setting: {
      tagValue: null,
      description: null,

      rate: {
        disabled: false,
        label: null,
        ratings: [],
      },
    },

    author: {
      publishDate: null,
      publishType: PublishTypes.Private,
      avatar: null,
      name: null,
      link: null,
    },
    // ----- ここまで

    dataDrawer: {
      show: false,
    },
    authorDrawer: {
      show: false,
    },
    dominantColorDrawer: {
      show: false,
    },

    colorDrawer: {
      show: false,
    },

    faceDrawer: {
      show: false,
    },

    imageDrawer: {
      show: false,
    },

    contentDrawer: {
      show: false,
      label: "",
      groupName: "h1",
    },

    entityDrawer: {
      show: false,
      label: "",
      groupName: "organization",
      icon: "",
    },

    linkDrawer: {
      show: false,
      tab: 0,
    },

    liteBox: {
      show: false,
      url: null,
      fileName: null, // ダウンロードファイル名
    },

    contentGroups: [
      {
        value: "h1",
        label: "h1",
        show: false,
      },
      {
        value: "h2",
        label: "h2",
        show: false,
      },
      {
        value: "h3",
        label: "h3",
        show: false,
      },
      {
        value: "h4",
        label: "h4",
        show: false,
      },
      {
        value: "h5",
        label: "h5",
        show: false,
      },
    ],

    entityGroups: [
      {
        value: "person",
        label: "Person",
        icon: "mdi-account",
        show: false,
      },
      {
        value: "organization",
        label: "Organization",
        icon: "mdi-domain",
        show: false,
      },
      {
        value: "commercialItem",
        label: "Commercial",
        icon: "mdi-bag-checked",
        show: false,
      },
      {
        value: "title",
        label: "Title",
        icon: "mdi-format-text",
        show: false,
      },
      {
        value: "location",
        label: "Location",
        icon: "mdi-map-marker",
        show: false,
      },
      {
        value: "other",
        label: "Other",
        icon: "mdi-bookmark-outline",
        show: false,
      },
    ],
  }),
  methods: {
    getDataCollection(ratings, label) {
      let labels = ratings.map((a) => a.label);
      let graphData = ratings.map((a) => a.value);

      let data = {
        labels: labels,
        datasets: [
          {
            backgroundColor: "",
            borderWidth: RadarChartConfig.dataset.borderWidth,
            clip: RadarChartConfig.dataset.clip,

            label: label,
            data: graphData,
          },
        ],
      };

      return data;
    },

    uniqArray(array) {
      return UniqArray(array);
    },

    onChange(data) {
      this.publishType = data.publishType;
      this.author = data.author;
      this.setting = data.setting;
    },

    // async onCommitPublishType() {
    async onCommitPublishType(publishType) {
      //
      //
      try {
        this.publishTypeEditor.loading = true;

        //
        const token = await this.recaptchaAsync("postsite");

        //
        const response = await this.$application.http.put("shot/publishType", {
          token: token,
          siteCode: this.siteShot.siteCode,
          queryCode: this.siteShot.queryCode,
          shotCode: this.siteShot.shotCode,
          secretCode: this.secretCode,
          //
          publishType: publishType,
          // publishType: this.publishTypeEditor.publishType,
        });
        //
        // this.publishType = this.publishTypeEditor.publishType;
        //
        const data = response.data;

        this.publishType = data.publishType;
        this.publishDate = data.publishDate;

        // イベントとして外部に伝える
        this.$emit("change", this);
        //
        this.publishTypeEditor.show = false;

        // storage保存されているshotを削除
        this.$application.storage.publishShot(
          this.siteShot.shortCode,
          this.publishType
        );
      } catch (error) {
        // 画面エラー表示
        this.publishTypeEditor.error = this.sanitizeError(
          this.publishTypeEditor.error,
          error
        );

        // publicフラグは下げる-> データ処理後のエラーでロールバックがないためこれはできない
        // this.publishType &= ~PublishTypes.Public;
      } finally {
        this.publishTypeEditor.loading = false;
      }
    },
  },
  async mounted() {
    // リアクティブに変えたい項目をコピー

    this.publishType = this.siteShot.publishType;
    this.publishTypeEditor.publishType = this.siteShot.publishType;

    this.author = Object.assign({}, this.siteShot.author);
    this.setting = Object.assign({}, this.siteShot.setting);
  },
};
</script>
