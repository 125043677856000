<template>
  <v-list>
    <v-list-item class="px-2" @click.stop="$emit('close')">
      <v-list-item-title>Data</v-list-item-title>
      <v-icon>mdi-chevron-left</v-icon>
    </v-list-item>
    <v-divider />
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>ContentType</v-list-item-title>
          <div class="text-caption">
            <v-icon
              v-text="$options.filters.contentTypeIcon(siteShot.contentType)"
              left
            />
            {{ siteShot.contentType }}

            <span> {{ siteShot.contentSize | fileSize }}</span>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Title</v-list-item-title>
          <div class="text-caption">
            {{ siteShot.content.title }}
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Description</v-list-item-title>

          <div class="text-caption">
            {{ siteShot.content.description }}
          </div>
        </v-list-item-content>
      </v-list-item>

      <template>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>OGP</v-list-item-title>

            <v-list class="text-caption" dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>siteName</v-list-item-title>
                  <div>{{ siteShot.content.og.siteName }}</div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>title</v-list-item-title>
                  <div>{{ siteShot.content.og.title }}</div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>description</v-list-item-title>
                  <div>{{ siteShot.content.og.description }}</div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>type</v-list-item-title>
                  <div>{{ siteShot.content.og.type }}</div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>url</v-list-item-title>
                  <div>{{ siteShot.content.og.url }}</div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>image</v-list-item-title>
                  <template v-if="siteShot.content.og.image">
                    <div>{{ siteShot.content.og.image }}</div>
                    <div>
                      <v-avatar tile>
                        <v-img :src="siteShot.content.og.image" />
                      </v-avatar>
                    </div>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-content>
        </v-list-item>

        <v-card class="ma-2 rounded-lg" outlined>
          <v-list-item class="pa-0">
            <v-list-item-avatar
              class="pa-0 ma-0 rounded-l-lg"
              color="grey"
              size="80"
              tile
            >
              <v-img :src="siteShot.content.og.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="pa-0 ma-2">
              <div>
                <v-list-item-title>
                  <a
                    class="text-decoration-none"
                    :href="siteShot.content.og.url"
                    target="_blank"
                    >{{ siteShot.content.og.title }}</a
                  >
                </v-list-item-title>
                <div class="text-caption">
                  {{ siteShot.content.og.description | lessStr(25) }}
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-bottom-sheet v-model="idJson.show" inset scrollable>
              <template v-slot:activator="{ on, attrs }">
                <a
                  color="red lighten-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="idJson.value = tryParseJson(siteShot.content.ldJson)"
                >
                  ld+json
                </a>
              </template>

              <v-card>
                <v-card-title>
                  <v-btn color="primary" text @click="idJson.show = false">
                    閉じる
                  </v-btn>
                  <v-spacer />
                  <span>ld+json</span>

                  <v-spacer
                /></v-card-title>

                <v-card-text>
                  <!-- <v-textarea
                    :value="
                      JSON.stringify(idJson.value, null, '\t').replace(
                        /\\/g,
                        ''
                      )
                    "
                    rows="30"
                  /> -->
                  <pre v-if="idJson.value != null"
                    >{{
                      JSON.stringify(idJson.value, null, "\t").replace(
                        /\\/g,
                        ""
                      )
                    }}
              </pre
                  >
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>FB</v-list-item-title>
          <v-list class="text-caption" dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>pages</v-list-item-title>
                <div>{{ siteShot.content.fb.pages }}</div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Twitter</v-list-item-title>

          <v-list class="text-caption" dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>card</v-list-item-title>
                <div>{{ siteShot.content.twitter.card }}</div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>site</v-list-item-title>
                <div>{{ siteShot.content.twitter.site }}</div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>accountId</v-list-item-title>
                <div>{{ siteShot.content.twitter.accountId }}</div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-item-content>
      </v-list-item>

      <!-- 
      <v-divider class="my-4" />
     
      <v-list-item>
        <v-list-item-title>
          <v-list-item-content>
            <v-list-item-title>Codes</v-list-item-title>
            <v-list-item-subtitle>{{ siteShot.siteCode }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{
              siteShot.queryCode
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ siteShot.shotCode }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item-title>
      </v-list-item> -->
    </v-list>
  </v-list>
</template>
<script>
export default {
  name: "",
  components: {},

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    siteShot: {
      type: Object,
      required: true,
    },
  },

  computed: {},

  data: () => ({
    initializing: true,

    idJson: {
      show: false,
      value: { hoge: "ftest", hoge2: "ftest2" },
    },
  }),
  methods: {
    async onLoadAsync() {},
  },
  async mounted() {},

  watch: {
    // 表示時
    show: {
      async handler(val) {
        if (val) await this.onLoadAsync();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
};
</script>
