var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',[_c('v-list-item',{staticClass:"px-2",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")]),_c('v-list-item-title',[_vm._v("Color")]),_c('v-spacer')],1),_c('v-divider'),_c('v-subheader',[_c('span',[_vm._v("DominantColor")]),_c('Dialog',[_c('span',[_vm._v("test2")])])],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('PieChart',{attrs:{"chart-data":_vm.dominantColorDataPieCollection,"options":{
            title: {
              display: true,
              text: 'カラー',
            },
            legend: {
              display: false,
            },
            scales: {},
          }}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":[
            {
              text: 'color',
              align: 'start',
              value: 'color',
            },
            { text: 'per', value: 'value' },
          ],"items":_vm.colorDatas,"items-per-page":-1},scopedSlots:_vm._u([{key:`item.color`,fn:function({ item }){return [_c('WCopyToClipBoard',{attrs:{"value":item.color}}),_c('v-chip',{class:`mr-2 ${
                _vm.$options.filters.isWhiteText(item.color)
                  ? 'white--text'
                  : 'black--text'
              }`,attrs:{"color":item.color},on:{"click":function($event){$event.stopPropagation();_vm.click();
                _vm.selectedColor = item.color;}}},[_c('span',[_vm._v(_vm._s(item.color))])])]}},{key:`item.count`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("number")(item.count))+" ")]}}],null,true)})],1),_c('v-list-item-subtitle')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }