<script>
// https://www.chartjs.org/docs
// https://memorva.jp/memo/html/chartjs_line_chart.php
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ["options"],

  mounted: function () {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
