<template>
  <v-list>
    <v-list-item class="px-2" @click.stop="$emit('close')">
      <v-icon>mdi-chevron-right</v-icon>
      <v-list-item-title>
        <v-icon v-text="icon" />
        {{ label }}
      </v-list-item-title>
      <v-spacer />
    </v-list-item>
    <v-divider />

    <v-list-item
      v-for="(entity, i) in siteShot.entityGroup[groupName]"
      :key="i"
    >
      <v-list-item-content>
        <v-list-item-subtitle>
          <WCopyToClipBoard :value="entity.text" />

          {{ entity.text }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <span class="text-caption">
          {{ entity.count }}
        </span>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "",
  components: {},

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    siteShot: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
    },
  },

  computed: {},

  data: () => ({
    initializing: true,
  }),
  methods: {
    async onLoadAsync() {},
  },
  async mounted() {
    await this.onLoadAsync();
  },
};
</script>
