<template>
  <v-list>
    <v-list-item class="px-2" @click.stop="$emit('close')">
      <v-icon>mdi-chevron-right</v-icon>
      <v-list-item-title>faces</v-list-item-title>
      <v-spacer />
    </v-list-item>
    <v-divider />

    <v-list>
      <v-list-item v-for="(face, i) in siteShot.face.faces" :key="i">
        <v-list-item-avatar color="grey">
          <v-img :src="face.src" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ face.name ? face.name : "-" }}</span>
          </v-list-item-title>
          <ul v-if="Array.isArray(face.urls)">
            <li v-for="(url, index) of face.urls" :key="index">
              <a
                class="text-caption text-decoration-none"
                :href="`//${url}`"
                target="_blkan"
                >{{ url }}</a
              >
            </li>
          </ul>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-subheader>
      <span>mapping</span>
    </v-subheader>
    <v-container>
      <v-bottom-sheet v-model="sheet" inset scrollable>
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on">
            <v-card max-width="150">
              <v-img :src="siteShot.face.src" max-width="150" />
            </v-card>
          </a>
        </template>
        <v-card>
          <v-card-title>
            <v-btn text color="warning" @click="sheet = !sheet"> 閉じる </v-btn>
          </v-card-title>
          <v-card-text>
            <v-img :src="siteShot.face.src" />
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </v-container>
  </v-list>
</template>

<script>
export default {
  name: "",
  components: {},

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    siteShot: {
      type: Object,
      required: true,
    },
  },

  computed: {},

  data: () => ({
    initializing: true,

    sheet: false,
  }),
  methods: {
    async onLoadAsync() {},
  },
  async mounted() {
    await this.onLoadAsync();
  },
};
</script>
