<template>
  <v-container>
    <v-overlay v-if="initializing">
      <v-progress-linear indeterminate size="64"></v-progress-linear>
      <div>コンテンツ作成中</div>
    </v-overlay>
    <v-fade-transition>
      <v-skeleton-loader
        v-if="initializing || siteShot == null || error != null"
        v-bind="{
          class: 'mb-6',
          elevation: 0,
          loading: true,
        }"
        type="image,table-heading, list-item-two-line, list-item-two-line, list-item-two-line "
      >
      </v-skeleton-loader>

      <div v-else>
        <Shot
          :siteShot="siteShot"
          :secretCode="secretCode"
          :securityCode="securityCode"
        />

        <v-divider class="my-2" />
      </div>
    </v-fade-transition>

    <!-- <v-input :error-messages="`${error.message}`" />
    <v-input :error-messages="error.errors.hoge1" /> -->
    <!-- <v-input error-count="2" :error-messages="error.errors" /> -->
  </v-container>
</template>

<script>
import Shot from "@/components/assets/shots/Shot";

import { PublishTypes } from "@/types";

export default {
  name: "",
  components: {
    Shot,
  },

  computed: {
    PublishTypes() {
      return PublishTypes;
    },
    shortCode() {
      return this.$route.params.shortCode;
    },
    secretCode() {
      return this.$route.params.secretCode;
    },
    securityCode() {
      return this.$route.params.securityCode;
    },
  },

  data: () => ({
    initializing: true,

    siteShot: {
      // face: {
      //   faces: [],
      // },
      // entities: [],
    },

    error: null,
  }),
  methods: {
    async onLoadAsync() {
      //
      try {
        this.initializing = true;
        this.error = null;

        const response = await this.$application.http.get("shortCode", {
          shortCode: this.shortCode,
          secretCode: this.secretCode,
        });
        // 仕様を変えたため、siteShotに後追加し一つにまとめることになった
        {
          const data = response.data;
          this.siteShot = data.shotData;
          this.siteShot.publishType = data.publishType;
          this.siteShot.publishDate = data.publishDate;
          this.siteShot.waterMarkType = data.waterMarkType;
          this.siteShot.waterMarkSrc = data.waterMarkSrc;

          this.siteShot.role = data.role;

          this.siteShot.openUrl = data.openUrl;
          this.siteShot.isAuthor = data.isAuthor;
          this.siteShot.setting = data.setting;
          this.siteShot.author = data.author;
          this.siteShot.history = data.history;
        }

        // 製作者としてストレージに作ったshotを登録
        if (this.siteShot.isAuthor) {
          //
          this.$application.storage.addShot(
            this.siteShot.shortCode,
            this.secretCode,
            this.siteShot.thumbnailData
          );
          //
          this.$application.storage.publishShot(
            this.siteShot.shortCode,
            this.siteShot.publishType
          );
        }
      } catch (error) {
        //
        this.error = error;

        //
      } finally {
        this.initializing = false;
      }
    },
  },
  async mounted() {
    // await this.onLoadAsync();
  },

  watch: {
    // URLが変更された場合を監視
    shortCode: {
      async handler(val) {
        if (val) await this.onLoadAsync();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
};
</script>
