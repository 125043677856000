<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on">
        <v-btn v-bind="attrs" v-on="on" small icon>
          <v-icon v-text="icon" small />
        </v-btn>
      </slot>
    </template>
    <v-card flat>
      <v-card-title>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon v-text="`mdi-close`" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot>
          <span>test</span>
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {},

  props: {
    icon: {
      type: String,
      default: () => "mdi-information-outline",
    },
  },

  computed: {},

  data: () => ({
    dialog: false,
  }),

  methods: {
    onLoaded() {},
  },

  watch: {},

  created() {},
};
</script>
