<template>
  <v-list three-line>
    <v-list-item class="px-2" @click.stop="$emit('close')">
      <v-icon>mdi-chevron-right</v-icon>
      <v-list-item-title>Images</v-list-item-title>
      <v-spacer />
    </v-list-item>
    <v-divider />

    <v-subheader>
      <span>images</span>
      <span>{{ `(${siteShot.content.images.length})` }}</span>
      <Dialog>
        <span>test2</span>
      </Dialog>
    </v-subheader>

    <v-list-item v-for="(img, i) in siteShot.content.images" :key="i">
      <v-list-item-avatar tile>
        <v-img
          v-if="img.isInnerAndAbsolute"
          :src="`${siteShot.scheme}://${siteShot.host}${img.src}`"
        />
        <v-img v-else :src="img.src" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-if="img.title">
          title{{ img.title }}
        </v-list-item-title>

        <!-- <v-list-item-subtitle>
          <span>
            {{ `${img.width} x ${img.height}` }}
          </span>
        </v-list-item-subtitle> -->
        <v-list-item-subtitle class="text-caption">
          <v-tooltip bottom v-if="img.isInnerAndAbsolute">
            <template v-slot:activator="{ on, attrs }">
              <WCopyToClipBoard
                :value="`${siteShot.scheme}://${siteShot.host}${img.src}`"
              />
              <span v-bind="attrs" v-on="on">
                {{ img.src }}
              </span>
            </template>
            {{ `${siteShot.scheme}://${siteShot.host}${img.src}` }}
          </v-tooltip>
          <span v-else>
            <WCopyToClipBoard :value="img.src" />
            {{ img.src }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <!-- <v-list-item-action>
        <v-list-item-action-text>
          {{ img.width | fileSize }}
        </v-list-item-action-text>
      </v-list-item-action> -->
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "",
  components: {},

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    siteShot: {
      type: Object,
      required: true,
    },
  },

  computed: {},

  data: () => ({
    initializing: true,
  }),
  methods: {
    async onLoadAsync() {},
  },
  async mounted() {
    await this.onLoadAsync();
  },
};
</script>
