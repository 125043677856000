<script>
// https://r17n.page/2020/09/18/vue-chartjs-change-chart-height/

// インストールはこちらを参照
// https://www.sky-limit-future.com/entry/error_vue_chartjs_version_1
// $ npm install chart.js@2.8
// $ npm install vue-chartjs@3.4.2

import { Radar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style></style>
