<template>
  <v-list dense>
    <v-list-item class="px-2" @click.stop="$emit('close')">
      <v-icon>mdi-chevron-right</v-icon>
      <v-list-item-title>Author</v-list-item-title>
      <v-spacer />
    </v-list-item>

    <v-subheader>
      <span>Access</span>
      <!-- <Dialog>
        <span></span>
      </Dialog> -->
      <v-divider inset />
    </v-subheader>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>SecretKey</v-list-item-title>
        <v-list-item-subtitle>
          <span>{{
            secretEditor.disp
              ? secretCode2
              : [...Array(secretCode2.length).keys()].map((a) => "*").join("")
          }}</span>
          <v-icon
            v-text="
              secretEditor.disp ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            "
            small
            @click="secretEditor.disp = !secretEditor.disp"
          />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <validation-observer v-slot="secretObserver">
          <v-bottom-sheet v-model="secretEditor.show" scrollable inset>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="
                  secretEditor.value = secretCode2;
                  secretEditor.error.errors = {
                    value: [],
                  };
                "
              >
                <v-icon v-text="`mdi-pencil-outline`" />
              </v-btn>
            </template>
            <v-card flat>
              <v-card-title>
                <v-btn icon @click="secretEditor.show = false">
                  <v-icon v-text="`mdi-close`" />
                </v-btn>
                <v-spacer />
              </v-card-title>
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  name="new secret code"
                  rules="required|max:60|alpha_num"
                >
                  <v-text-field
                    class="mt-4"
                    v-model="secretEditor.value"
                    :error-messages="errors"
                    label="*secret code"
                    maxlength="60"
                    hint="must be number or alphabet."
                    persistent-hint
                    counter
                    outlined
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  :disabled="secretObserver.invalid"
                  :loading="secretEditor.loading"
                  @click="onCommitSecretCode"
                >
                  決定
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-bottom-sheet>
        </validation-observer>
      </v-list-item-action>
    </v-list-item>

    <template v-if="(ShotRoles.Publish & role) != 0">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>公開URL</v-list-item-title>
          <v-list-item-subtitle>
            <WCopyToClipBoard :value="siteShot.openUrl" />

            <span>{{ siteShot.openUrl }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>管理者URL</v-list-item-title>
          <v-list-item-subtitle>
            <WCopyToClipBoard :value="url" />

            <span>{{ url }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-slide-y-transition mode="in-out">
      <!-- <v-list v-if="(publishType & PublishTypes.Public) != 0"> -->
      <v-list>
        <template v-if="(ShotRoles.Author & role) != 0">
          <v-subheader class="mt-4">
            <span>Author</span>
            <!-- <Dialog>
              <span></span>
            </Dialog> -->
            <v-divider inset />
          </v-subheader>

          <v-list-item>
            <v-list-item-avatar color="grey">
              <v-img :src="author.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <!-- <div>
              <v-chip
                v-if="(author.publishType & PublishTypes.Public) != 0"
                :color="publishColor(true)"
                x-small
                >公開</v-chip
              >
              <v-chip v-else outlined color="warning" x-small>非公開</v-chip>
            </div> -->
              <v-list-item-title>
                <span>{{ author.name }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ author.link }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <validation-observer v-slot="authorObserver">
                <v-bottom-sheet v-model="authorEditor.show" inset scrollable>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        {
                          // authorEditor.publishType = author.publishType;
                          // authorEditor.publishDate = author.publishDate;
                          authorEditor.name = author.name;
                          authorEditor.avatar = author.avatar;
                          authorEditor.link = author.link;
                          authorEditor.error.errors = {
                            name: [],
                            avatar: [],
                            link: [],
                          };
                        }
                      "
                    >
                      <v-icon v-text="`mdi-pencil-outline`" />
                    </v-btn>
                  </template>
                  <v-card flat>
                    <v-card-title>
                      <v-btn icon @click="authorEditor.show = false">
                        <v-icon v-text="`mdi-close`" />
                      </v-btn>

                      <v-spacer />
                    </v-card-title>

                    <v-card-text>
                      <v-subheader>avatar</v-subheader>
                      <v-avatar color="grey">
                        <v-img :src="authorEditor.avatar">
                          <!-- <v-avatar color="red">
                        <v-btn icon>
                          <v-icon v-text="`mdi-pencil-outline`" />
                        </v-btn>
                      </v-avatar> -->
                        </v-img>
                      </v-avatar>

                      <!-- <validation-provider
                          v-slot="{ validate, errors }"
                          name="avatarfile"
                          :rules="`rule1:1,${2 * 11}`"
                        > -->
                      <validation-provider
                        v-slot="{ validate, errors }"
                        name="avatarfile"
                      >
                        <v-btn
                          class="ml-2"
                          outlined
                          @click="onAvatarUpload(validate)"
                        >
                          upload
                        </v-btn>

                        <v-input
                          v-model="authorEditor.avatar"
                          :error-messages="
                            concatErrors(
                              errors,
                              authorEditor.error.errors.avatar
                            )
                          "
                        >
                        </v-input>
                      </validation-provider>

                      <v-subheader>*Name</v-subheader>

                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required|max:30"
                      >
                        <v-text-field
                          v-model="authorEditor.name"
                          outlined
                          :error-messages="
                            concatErrors(errors, authorEditor.error.errors.name)
                          "
                          maxlength="30"
                          counter
                          dense
                        />
                      </validation-provider>

                      <v-subheader>link</v-subheader>
                      <validation-provider
                        v-slot="{ errors }"
                        name="link"
                        rules="max:150"
                      >
                        <v-text-field
                          v-model="authorEditor.link"
                          outlined
                          maxlength="150"
                          counter
                          placeholder="https://"
                          :error-messages="
                            concatErrors(errors, authorEditor.error.errors.link)
                          "
                          dense
                        />
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        :disabled="authorObserver.invalid"
                        :loading="authorEditor.loading"
                        @click="onCommitAuthor"
                      >
                        決定
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-bottom-sheet>
              </validation-observer>
            </v-list-item-action>
          </v-list-item>
        </template>

        <template v-if="(ShotRoles.Tag & role) != 0">
          <v-subheader>
            <span>Tag</span>
            <!-- <Dialog>
              <span></span>
            </Dialog> -->
            <v-divider inset />
          </v-subheader>

          <v-list-item>
            <v-list-item-content>
              <div v-if="Array.isArray(setting.tags)">
                <v-chip
                  class="ma-1"
                  v-for="(tag, i) of uniqArray(setting.tags)"
                  :key="i"
                  label
                  outlined
                  small
                >
                  {{ tag }}
                </v-chip>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <validation-observer v-slot="tagObserver">
                <v-bottom-sheet v-model="tagEditor.show" scrollable inset>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        tagEditor.value = Array.isArray(setting.tags)
                          ? setting.tags.join(',')
                          : '';
                        tagEditor.error.errors = {
                          value: [],
                        };
                      "
                    >
                      <v-icon v-text="`mdi-pencil-outline`" />
                    </v-btn>
                  </template>
                  <v-card flat>
                    <v-card-title>
                      <v-btn
                        icon
                        :loading="tagEditor.loading"
                        @click="tagEditor.show = false"
                      >
                        <v-icon v-text="`mdi-close`" />
                      </v-btn>

                      <v-spacer />
                    </v-card-title>
                    <v-card-text>
                      <v-subheader>tag</v-subheader>

                      <div v-if="tagEditor.value != null">
                        <v-chip
                          class="ma-1"
                          v-for="(tag, i) of uniqArray(
                            tagEditor.value.split(',').filter((a) => a)
                          )"
                          :key="i"
                          label
                          outlined
                          small
                          close
                          @click:close="onDeleteTag(tag)"
                        >
                          {{ tag }}
                        </v-chip>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="tag"
                        rules="max:100"
                      >
                        <v-textarea
                          v-model="tagEditor.value"
                          :error-messages="
                            concatErrors(errors, tagEditor.error.errors.value)
                          "
                          maxlength="100"
                          counter
                          outlined
                          @blur="onChangeTag"
                        />
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click="onChangeTag">distinct</v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        :disabled="tagObserver.invalid"
                        :loading="tagEditor.loading"
                        @click="onCommitTags()"
                      >
                        決定
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-bottom-sheet>
              </validation-observer>
            </v-list-item-action>
          </v-list-item>
        </template>

        <template v-if="(ShotRoles.Description & role) != 0">
          <v-subheader>
            <span>description</span>
            <!-- <Dialog>
              <span></span>
            </Dialog> -->
            <v-divider inset />
          </v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-card outlined height="200" style="overflow: auto">
                <v-card-text>
                  <p v-html="$options.filters.md(setting.description)"></p>
                </v-card-text>
              </v-card>
              <!-- <v-textarea
                v-model="setting.description"
                maxlength="1000"
                counter="1000"
                outlined
                dense
                readonly
              /> -->
            </v-list-item-content>

            <v-list-item-action>
              <validation-observer v-slot="descriptionObserver">
                <v-bottom-sheet
                  v-model="descriptionEditor.show"
                  scrollable
                  inset
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        descriptionEditor.description = setting.description;
                        descriptionEditor.error.errors = {
                          description: [],
                        };
                      "
                    >
                      <v-icon v-text="`mdi-pencil-outline`" />
                    </v-btn>
                  </template>
                  <v-card flat>
                    <v-card-title>
                      <v-btn
                        text
                        color="warning"
                        :loading="descriptionEditor.loading"
                        @click="descriptionEditor.show = false"
                        >閉じる</v-btn
                      >
                    </v-card-title>
                    <v-card-text>
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                        rules="max:1000"
                      >
                        <v-textarea
                          v-model="descriptionEditor.description"
                          :error-messages="
                            concatErrors(errors, descriptionEditor.error.errors)
                          "
                          rows="20"
                          maxlength="1000"
                          counter="1000"
                          outlined
                          dense
                        />
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        :loading="descriptionEditor.loading"
                        :disabled="descriptionObserver.invalid"
                        @click="onCommitDescription"
                        >登録</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-bottom-sheet>
              </validation-observer>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-slide-y-transition>

    <template v-if="(ShotRoles.Rate & role) != 0">
      <v-subheader>
        <Dialog v-if="rateEditor.error.message">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small>
              <v-icon color="error" v-text="'mdi-alert-outline'" small />
            </v-btn>
          </template>
          <v-alert text dense type="error">
            {{ rateEditor.error.message }}
          </v-alert>
        </Dialog>

        <v-icon v-if="setting.rate.disabled" v-text="`mdi-eye-off-outline`" />

        <span>Recommend</span>
        <!-- <Dialog>
          <span></span>
        </Dialog> -->
        <v-divider inset />
      </v-subheader>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-sheet v-if="setting.rate.ratings.length >= 3" max-width="230">
              <RadarChart
                :chart-data="
                  getDataCollection(setting.rate.ratings, setting.rate.label)
                "
                :options="graphOptions"
              ></RadarChart>
            </v-sheet>

            <div v-else>
              <span class="text-caption">{{ setting.rate.label }}</span>
              <v-list-item
                v-for="(rate, index) of setting.rate.ratings"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ rate.label }}</v-list-item-title>
                </v-list-item-content>

                <v-rating
                  :value="rate.value"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>

                <v-list-item-action class="text-left">
                  <span class="text-caption grey--text">
                    {{ rate.value }}
                  </span>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <validation-observer v-slot="rateObserver">
              <v-bottom-sheet
                v-model="rateEditor.show"
                scrollable
                persistent
                inset
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      if (siteShot.setting.rate != null) {
                        rateEditor.disabled = setting.rate.disabled;
                        rateEditor.label = setting.rate.label;
                        rateEditor.ratings = setting.rate.ratings.map((a) =>
                          Object.assign({}, a)
                        );
                      }
                    "
                  >
                    <v-icon v-text="`mdi-pencil-outline`" />
                  </v-btn>
                </template>
                <v-card flat>
                  <v-card-title>
                    <v-btn
                      icon
                      :loading="rateEditor.loading"
                      @click="rateEditor.show = false"
                    >
                      <v-icon v-text="`mdi-close`" />
                    </v-btn>
                    <v-spacer />
                  </v-card-title>
                  <v-card-text>
                    <v-card flat>
                      <v-card-text>
                        <validation-provider
                          v-slot="{ errors }"
                          name="disabled"
                        >
                          <v-switch
                            v-model="rateEditor.disabled"
                            label="有効"
                            :false-value="true"
                            :true-value="false"
                            :value="true"
                            :error-messages="
                              concatErrors(
                                errors,
                                rateEditor.error.errors.disabled
                              )
                            "
                          />
                        </validation-provider>
                        <validation-provider
                          v-slot="{ errors }"
                          name="label"
                          rules="max:30"
                        >
                          <v-text-field
                            label="*グラフラベル"
                            v-model="rateEditor.label"
                            :error-messages="
                              concatErrors(
                                errors,
                                rateEditor.error.errors.label
                              )
                            "
                            maxlength="30"
                            counter
                            outlined
                          />
                        </validation-provider>
                        <v-row align="center" justify="center">
                          <v-col cols="12" md="9">
                            <v-btn
                              color="primary mb-2 title--text"
                              :disabled="rateEditor.ratings.length >= 7"
                              small
                              outlined
                              @click="onAddRating()"
                            >
                              <v-icon v-text="'mdi-plus-circle-outline'" left />
                              追加
                              <span class="ml-2"
                                >({{ rateEditor.ratings.length }} / 5)</span
                              >
                            </v-btn>

                            <v-input
                              :error-messages="rateEditor.error.errors.ratings"
                              disabled
                            >
                              <template v-slot:message="{ message }">
                                <span class="error--text">{{ message }}</span>
                              </template>
                            </v-input>

                            <v-card-text>
                              <v-row
                                v-for="(r, index) in rateEditor.ratings"
                                align="center"
                                justify="center"
                                :key="index"
                                dense
                              >
                                <v-col cols="6">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    :name="`label${index + 1}`"
                                    rules="required|max:10"
                                  >
                                    <v-text-field
                                      v-model="r.label"
                                      :label="`項目${index + 1}のラベル`"
                                      dense
                                      maxlength="10"
                                      counter
                                      :error-messages="
                                        concatErrors(
                                          errors,
                                          rateEditor.error.errors[
                                            `ratings[${index}].label`
                                          ]
                                        )
                                      "
                                      outlined
                                    />
                                  </validation-provider>
                                </v-col>
                                <v-col cols="5">
                                  <v-rating
                                    half-increments
                                    v-model="r.value"
                                    small
                                    counter
                                    :maxlength="5"
                                    outlined
                                  ></v-rating>
                                </v-col>
                                <v-col cols="1">
                                  <v-btn
                                    icon
                                    color="warning"
                                    @click="onDeleteRating(r.code)"
                                  >
                                    <v-icon v-text="`mdi-delete-outline`" />
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                            v-if="rateEditor.ratings.length >= 3"
                          >
                            <RadarChart
                              :chart-data="
                                getDataCollection(
                                  rateEditor.ratings,
                                  rateEditor.label
                                )
                              "
                              :options="graphOptions"
                            ></RadarChart>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :disabled="rateObserver.invalid"
                      :loading="rateEditor.loading"
                      color="primary"
                      @click="onCommitRate"
                    >
                      決定
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-bottom-sheet>
            </validation-observer>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>

    <!-- 
    <v-divider class="my-2" />

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Log</v-list-item-title>

        <v-card class="text-center" color="green" flat @click="drawer2 = false">
          <v-sheet color="rgba(0, 0, 0, .12)">
            <v-sparkline
              :value="[423, 446, 675, 510, 590, 610, 760]"
              color="rgba(255, 255, 255, .7)"
              height="100"
              padding="15"
              stroke-linecap="round"
              smooth
            >
              <template v-slot:label="item"> {{ item.value }} </template>
            </v-sparkline>
          </v-sheet>
        </v-card>
      </v-list-item-content>
    </v-list-item> -->

    <template v-if="(ShotRoles.WaterMark & role) != 0">
      <v-subheader>
        <span>Watermark</span>
        <!-- <Dialog>
          <span></span>
        </Dialog> -->
        <v-divider inset />
      </v-subheader>

      <v-list-item>
        <v-badge
          bordered
          :icon="
            (waterMarkType & WaterMarkTypes.ScreenShot) != 0
              ? `mdi-eye-plus-outline`
              : `mdi-eye-off-outline`
          "
          :color="
            (waterMarkType & WaterMarkTypes.ScreenShot) != 0
              ? `primary`
              : `warning`
          "
          overlap
        >
          <v-list-item-avatar tile color="grey">
            <v-img :src="waterMarkSrc" />
          </v-list-item-avatar>
        </v-badge>
        <v-list-item-content></v-list-item-content>
        <v-list-item-action>
          <validation-observer v-slot="secretObserver">
            <v-bottom-sheet
              v-model="watermarkEditor.show"
              scrollable
              width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    watermarkEditor.waterMarkType = waterMarkType;
                    watermarkEditor.files = [];
                    watermarkEditor.fileDatas = [];
                    watermarkEditor.fileMD5s = [];

                    if (waterMarkSrc.length > 0) {
                      watermarkEditor.fileDatas.push(waterMarkSrc);
                    }
                    watermarkEditor.error.errors = {
                      value: [],
                    };
                  "
                >
                  <v-icon v-text="`mdi-pencil-outline`" />
                </v-btn>
              </template>
              <v-card flat>
                <v-card-title>
                  <v-btn icon @click="watermarkEditor.show = false">
                    <v-icon v-text="`mdi-close`" />
                  </v-btn>
                  <v-spacer />
                </v-card-title>
                <v-card-text>
                  <v-avatar tile color="grey" size="250">
                    <v-img
                      :src="
                        Array.isArray(watermarkEditor.fileDatas) &&
                        watermarkEditor.fileDatas.length > 0
                          ? watermarkEditor.fileDatas[0]
                          : null
                      "
                      aspect-ratio="1"
                    >
                      <v-app-bar color="transparent" flat>
                        <v-spacer />
                        <v-switch
                          v-model="watermarkEditor.waterMarkType"
                          :true-value="1 << 1"
                          :false-value="1"
                        />
                      </v-app-bar>
                      <v-footer absolute color="transparent">
                        <v-spacer />
                        <v-btn
                          class="text-none"
                          color="primary"
                          small
                          @click="onUploadWatermark"
                        >
                          <v-icon v-text="`mdi-file-jpg-box`" left />
                          onUpload
                        </v-btn>
                      </v-footer>
                    </v-img>
                  </v-avatar>

                  <v-list-item>
                    <!-- <v-list-item-content>
                  <v-list-item-title>
                    {{ watermarkEditor.files[0].name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-spacer />
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ watermarkEditor.files[0].size | fileSize }}
                  </v-list-item-action-text>
                  <span class="text-caption">
                    {{ watermarkEditor.files[0].type }}
                  </span>
                </v-list-item-action> -->
                  </v-list-item>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="secretObserver.invalid"
                    :loading="watermarkEditor.loading"
                    outlined
                    @click="onCommitWatermark"
                  >
                    決定
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-bottom-sheet>
          </validation-observer>
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-divider class="mt-12" />

    <v-list-item>
      <validation-observer v-slot="secretObserver">
        <v-bottom-sheet v-model="keyEditor.show" scrollable inset>
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-chip
              outlined
              v-bind="attrs"
              v-on="on"
              @click="
                keyEditor.value = secretCode2;
                keyEditor.error.errors = {
                  value: [],
                };
              "
            >
              keyEditor
            </v-chip>
          </template> -->
          <v-card flat>
            <v-card-title>
              <v-btn icon @click="keyEditor.show = false">
                <v-icon v-text="`mdi-close`" />
              </v-btn>
              <v-spacer />
            </v-card-title>
            <v-card-text>
              <validation-provider
                v-slot="{ errors }"
                name="new secret code"
                rules="required|max:32"
              >
                <v-text-field
                  class="mt-4"
                  v-model="keyEditor.value"
                  :error-messages="errors"
                  label="code"
                  maxlength="32"
                  counter
                  outlined
                />
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="secretObserver.invalid"
                :loading="keyEditor.loading"
                @click="onCommitKeyCode"
              >
                決定
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>
      </validation-observer>

      <v-spacer />
      <v-list-item-action>
        <v-btn
          color="red"
          :disabled="deleteEditor.loading"
          outlined
          @click="onDelete"
        >
          <v-icon v-text="`mdi-trash-can-outline`" />削除
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <!-- シークレット変更後ダイアログ -->
    <v-dialog v-model="urlDialog.show" width="70%">
      <v-card>
        <v-card-title>
          <v-btn text color="warning" @click="urlDialog.show = false"
            >閉じる</v-btn
          >
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-subheader>管理者URLが変更されました</v-subheader>

            <v-text-field
              ref="urlDialogUrl"
              :value="urlDialog.url"
              readonly
              outlined
              dense
            >
              <template v-slot:prepend>
                <WCopyToClipBoard :value="urlDialog.url" />
              </template>
            </v-text-field>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-list>

  <!-- <template v-slot:append>
        <v-list-item>
          <v-list-item-content>
            <v-btn color="warning">
              <v-icon v-text="`mdi-trash-can-outline`" />削除
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </template> -->
</template>

<script>
import { PublishTypes, WaterMarkTypes, ShotRoles } from "@/types";
import { AppValidateException } from "@/exceptions/appExceptions";

import Dialog from "@/components/dialogs/Dialog";
import RadarChart from "@/components/charts/RadarChart";

import { UniqArray } from "@/util/array";

import { uploaderAsync, readFileAsync, resizeAsync, getMd5 } from "@/util/file";

// vee-validate カスタムルール
import { extend } from "vee-validate";
extend("alpha_num", {
  message: (fieldName) =>
    `The field [${fieldName}] must be numeric or alphabet `,
  validate(value) {
    return value.match(/^[A-Za-z0-9]*$/);
  },
});

const { v4: uuidv4 } = require("uuid");

const RadarChartConfig = {
  dataset: {
    backgroundColor: "rgba(187, 200, 230, .5)", // 淡藤色
    borderWidth: 1,
    clip: 5,
    label: "評価",
  },
};

export default {
  name: "",
  components: { Dialog, RadarChart },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    siteShot: {
      type: Object,
      required: true,
    },

    secretCode: {
      type: String,
    },
    securityCode: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    PublishTypes() {
      return PublishTypes;
    },
    WaterMarkTypes() {
      return WaterMarkTypes;
    },
    ShotRoles() {
      return ShotRoles;
    },

    siteCode() {
      return this.siteShot.siteCode;
    },
    queryCode() {
      return this.siteShot.queryCode;
    },
    shotCode() {
      return this.siteShot.shotCode;
    },
    shortCode() {
      return this.siteShot.shortCode;
    },

    graphOptions() {
      let options = {
        // width , heightを有効にするため
        // responsive: false,
        scale: {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 5,
            stepSize: 1.0,
            // callback: function (value, index, values) {
            callback: function (value) {
              // return  value +  '単位'
              return value;
            },
          },
        },
      };
      return options;
    },

    url() {
      if (location) return location.href;
      return null;
    },
  },

  data: () => ({
    // loading: false,

    // error: {
    //   message: null,
    //   errors: {},
    // },

    urlDialog: {
      show: false,
      url: null,
    },

    secretCode2: "", //nullだとlengthが取れないので注意
    publishDate: null,
    publishType: PublishTypes.Private,

    waterMarkType: WaterMarkTypes.ScreenShot,
    waterMarkSrc: null,

    role: ShotRoles.None,

    setting: {
      tagValue: null,
      description: null,

      rate: {
        disabled: false,
        label: null,
        ratings: [],
      },
    },

    author: {
      avatar: null,
      name: null,
      link: null,
    },

    deleteEditor: {
      loading: false,
    },

    authorEditor: {
      loading: false,
      show: false,
      avatar: null,
      name: null,
      link: null,

      error: {
        message: null,
        errors: {
          name: [],
          avatar: [],
          link: [],
        },
      },
    },

    descriptionEditor: {
      loading: false,
      show: false,
      description: null,
      error: {
        message: null,
        errors: { description: [] },
      },
    },

    secretEditor: {
      loading: false,
      show: false,
      disp: false,
      value: "",
      error: {
        message: null,
        errors: { value: [] },
      },
    },

    tagEditor: {
      loading: false,
      show: false,
      value: "",
      tags: [],
      error: {
        errors: {
          value: [],
        },
      },
    },

    rateEditor: {
      loading: false,
      show: false,
      disabled: true,
      label: null,
      ratings: [],

      error: {
        message: "",
        errors: {
          label: [],
          ratings: [],
        },
      },
    },

    watermarkEditor: {
      loading: false,
      show: false,
      waterMarkType: WaterMarkTypes.ScreenShot,
      files: [],
      fileDatas: [],
      fileMD5s: [],
      error: {
        message: null,
        errors: { value: [] },
      },
    },

    keyEditor: {
      loading: false,
      show: false,
      value: "",
      error: {
        message: null,
        errors: { value: [] },
      },
    },
  }),
  methods: {
    async onLoadAsync() {
      // editorに移し替え
      // this.authorEditor.publishType = this.siteShot.author.publishType;
      // this.authorEditor.name = this.siteShot.author.name;
      // this.authorEditor.link = this.siteShot.author.link;
      // this.authorEditor.avatar = this.siteShot.author.avatar;
      // this.publishTypeEditor.publishType = this.siteShot.setting.publishType;
      // if (this.siteShot.setting.rate != null) {
      //   this.rateEditor.disabled = this.siteShot.setting.rate.disabled;
      //   this.rateEditor.label = this.siteShot.setting.rate.label;
      //   this.rateEditor.ratings = this.siteShot.setting.rate.ratings.map(
      //     (a) => ({
      //       code: a.code,
      //       label: a.label,
      //       value: a.value,
      //     })
      //   );
      // }
      // this.secretEditor.value = this.secretCode;
      // this.tagEditor.value = this.siteShot.setting.tagValue;
    },

    async onCommitSecretCode() {
      // シークレットコードの変更
      try {
        this.secretEditor.loading = true;
        //
        const token = await this.recaptchaAsync("postsite");

        //
        const response = await this.$application.http.put("shot/secretCode", {
          token: token,
          siteCode: this.siteCode,
          queryCode: this.queryCode,
          shotCode: this.shotCode,
          secretCode: this.secretCode2,
          //
          newSecretCode: this.secretEditor.value,
        });

        this.secretCode2 = this.secretEditor.value;

        this.secretEditor.show = false;

        // 現在のアドレスを書き換える
        history.pushState(
          null,
          null,
          `/shot/${this.shortCode}/${this.secretEditor.value}`
        );

        // storageを変える
        this.$application.storage.changeShotSecretCode(
          this.shortCode,
          this.secretCode2,
          this.secretEditor.value
        );

        // 書き換わったURL表示ダイアログ表示
        const url = response.data.url;
        this.urlDialog.url = url;
        this.urlDialog.show = true;
        this.$refs["urlDialogUrl"].setSelectionRange(); // カーソル位置を先頭にさせる
      } catch (error) {
        // 画面エラー表示
        this.secretEditor.error = this.sanitizeError(
          this.secretEditor.error,
          error
        );
      } finally {
        this.secretEditor.loading = false;
      }
    },

    async onCommitAuthor() {
      //
      try {
        this.authorEditor.loading = true;

        //
        const token = await this.recaptchaAsync("postsite");

        await this.$application.http.put("shot/author", {
          token: token,
          siteCode: this.siteCode,
          queryCode: this.queryCode,
          shotCode: this.shotCode,
          secretCode: this.secretCode2,
          //
          // publishType: this.authorEditor.publishType,
          name: this.authorEditor.name,
          avatar: this.authorEditor.avatar,
          link: this.authorEditor.link,
        });

        //
        // this.author.publishType = this.authorEditor.publishType;
        this.author.name = this.authorEditor.name;
        this.author.link = this.authorEditor.link;
        this.author.avatar = this.authorEditor.avatar;

        this.authorEditor.show = false;

        // イベントとして外部に伝える
        this.$emit("change", this);
      } catch (error) {
        // 画面エラー表示
        this.authorEditor.error = this.sanitizeError(
          this.authorEditor.error,
          error
        );
      } finally {
        this.authorEditor.loading = false;
      }
    },

    async onCommitTags() {
      //

      //
      try {
        this.tagEditor.loading = true;
        //
        const token = await this.recaptchaAsync("postsite");
        //
        await this.$application.http.put("shot/setting/tag", {
          token: token,
          siteCode: this.siteCode,
          queryCode: this.queryCode,
          shotCode: this.shotCode,
          secretCode: this.secretCode2,
          //
          value: this.tagEditor.value,
        });
        //
        // this.setting.tags = this.tagEditor.tags;

        this.setting.tagValue = this.tagEditor.value;

        this.tagEditor.show = false;

        // イベントとして外部に伝える
        this.$emit("change", this);
      } catch (error) {
        // 画面エラー表示
        this.tagEditor.error = this.sanitizeError(this.tagEditor.error, error);
      } finally {
        this.tagEditor.loading = false;
      }
    },

    async onCommitDescription() {
      //
      try {
        this.descriptionEditor.loading = true;

        //
        const token = await this.recaptchaAsync("postsite");
        //
        await this.$application.http.put("shot/setting/description", {
          token: token,
          siteCode: this.siteCode,
          queryCode: this.queryCode,
          shotCode: this.shotCode,
          secretCode: this.secretCode2,
          //
          description: this.descriptionEditor.description,
        });
        //
        this.setting.description = this.descriptionEditor.description;

        this.descriptionEditor.show = false;

        // イベントとして外部に伝える
        this.$emit("change", this);
      } catch (error) {
        // 画面エラー表示
        this.descriptionEditor.error = this.sanitizeError(
          this.descriptionEditor.error,
          error
        );
      } finally {
        this.descriptionEditor.loading = false;
      }
    },

    async onCommitRate() {
      //
      try {
        this.rateEditor.loading = true;

        //
        const token = await this.recaptchaAsync("postsite");
        //
        await this.$application.http.put("shot/rate", {
          token: token,
          siteCode: this.siteCode,
          queryCode: this.queryCode,
          shotCode: this.shotCode,
          secretCode: this.secretCode2,
          //
          disabled: this.rateEditor.disabled,
          label: this.rateEditor.label,
          ratings: this.rateEditor.ratings,
        });
        //

        this.setting.rate.disabled = this.rateEditor.disabled;
        this.setting.rate.label = this.rateEditor.label;
        this.setting.rate.ratings = this.rateEditor.ratings;

        this.rateEditor.show = false;
        // イベントとして外部に伝える
        this.$emit("change", this);
      } catch (error) {
        // 画面エラー表示
        this.rateEditor.error = this.sanitizeError(
          this.rateEditor.error,
          error
        );
      } finally {
        this.rateEditor.loading = false;
      }
    },

    async onUploadWatermark() {
      const files = await uploaderAsync({
        accept: "image/jpg",
        multiple: false,
      });
      //
      // .map を持たないためこのやり方で展開
      let uploads = [];
      for (let i = 0; i < files.length; i++) {
        uploads.push(files[i]);
      }

      // 一応ここでもバリデーションする
      for (let i = 0; i < uploads.length; i++) {
        const upload = uploads[i];
        if (!["image/jpeg", "image/jpg"].includes(upload.type)) {
          throw new AppValidateException();
        }
      }

      this.watermarkEditor.files = [];
      this.watermarkEditor.fileDatas = [];
      this.watermarkEditor.fileMD5s = [];
      for (let i = 0; i < uploads.length; i++) {
        const upload = uploads[i];

        //
        let d2 = null;
        if (upload.type.startsWith("image")) {
          const d1 = await readFileAsync(uploads[i]);
          d2 = await resizeAsync(d1, 150);
        }

        const md5 = await getMd5(upload);
        this.watermarkEditor.files.push(upload);
        this.watermarkEditor.fileDatas.push(d2);
        this.watermarkEditor.fileMD5s.push(md5);
      }
    },
    async onCommitWatermark() {
      // ウォーターマーク設定
      try {
        this.watermarkEditor.loading = true;
        //
        const token = await this.recaptchaAsync("key");

        //
        const response = await this.$application.http.postUpload(
          "shot/watermark",
          {
            token: token,
            siteCode: this.siteCode,
            queryCode: this.queryCode,
            shotCode: this.shotCode,
            secretCode: this.secretCode,
            //
            waterMarkType: this.watermarkEditor.waterMarkType,
            fileMD5s: this.watermarkEditor.fileMD5s,
          },
          this.watermarkEditor.files
        );

        //
        const data = response.data;

        this.waterMarkType = data.waterMarkType;

        if (this.watermarkEditor.fileDatas.length > 0) {
          this.waterMarkSrc = this.watermarkEditor.fileDatas[0];
        }

        this.watermarkEditor.show = false;
      } catch (error) {
        //

        // 画面エラー表示
        this.watermarkEditor.error = this.sanitizeError(
          this.watermarkEditor.error,
          error
        );
      } finally {
        this.watermarkEditor.loading = false;
      }
    },

    async onCommitKeyCode() {
      // シークレットコードの変更
      try {
        this.keyEditor.loading = true;
        //
        const token = await this.recaptchaAsync("key");

        //
        const response = await this.$application.http.put("shot/key", {
          token: token,
          siteCode: this.siteCode,
          queryCode: this.queryCode,
          shotCode: this.shotCode,
          secretCode: this.secretCode,
          //
          key: this.keyEditor.value,
        });

        //
        const data = response.data;
        this.role = data.role;

        this.keyEditor.show = false;
      } catch (error) {
        //

        // 画面エラー表示
        this.keyEditor.error = this.sanitizeError(this.keyEditor.error, error);
      } finally {
        this.keyEditor.loading = false;
      }
    },

    async onDelete() {
      //
      if (
        !this.$application.app.confirm("MC0041", "削除してもよろしいですか？")
      )
        return;

      try {
        this.deleteEditor.loading = true;
        //
        const token = await this.recaptchaAsync("postsite");

        await this.$application.http.delete("shot", {
          token: token,
          siteCode: this.siteCode,
          queryCode: this.queryCode,
          shotCode: this.shotCode,
          secretCode: this.secretCode2,
          securityCode: this.securityCode,
        });

        // storage保存
        this.$application.storage.deleteShot(this.shortCode);

        location.href = "/";
      } catch (error) {
        // 画面エラー表示
        this.deleteEditor.error = this.sanitizeError(
          this.deleteEditor.error,
          error
        );
      } finally {
        this.deleteEditor.loading = false;
      }
    },

    async onAvatarUpload(validate) {
      const files = await uploaderAsync({
        accept: "image/*",
        multiple: false,
      });
      //
      const fileData = await readFileAsync(files[0]);
      //
      const resized = await resizeAsync(fileData, 150);
      //
      // base64:の文字列形式で保存する
      this.authorEditor.avatar = resized;
      //

      // this.$set(this.authorEditor, "avatar", resized);
      // await this.$refs.vp_avatar.validate();

      this.$nextTick(() => {
        // this.authorEditor.avatar = resized;
        validate();
      });
    },

    /** */

    onAddRating() {
      this.rateEditor.ratings.push({
        code: `${uuidv4().replace(/-/g, "")}`,
        label: `label${this.rateEditor.ratings.length + 1}`,
        value: 3.5,
      });
    },

    onDeleteRating(code) {
      this.rateEditor.ratings = this.rateEditor.ratings.filter(
        (a) => a.code != code
      );
    },

    getDataCollection(ratings, label) {
      let labels = ratings.map((a) => a.label);
      let graphData = ratings.map((a) => a.value);

      let data = {
        labels: labels,
        datasets: [
          {
            backgroundColor: "",
            borderWidth: RadarChartConfig.dataset.borderWidth,
            clip: RadarChartConfig.dataset.clip,

            label: label,
            data: graphData,
          },
        ],
      };

      return data;
    },

    onChangeTag() {
      if (this.tagEditor.value == null) return;
      const tag = UniqArray(this.tagEditor.value.split(",").filter((a) => a));
      this.tagEditor.value = tag.join(",");
    },

    onDeleteTag(tag) {
      let tags = this.tagEditor.value.split(",");
      this.tagEditor.value = tags.filter((a) => a != tag).join(",");
    },

    uniqArray(array) {
      return UniqArray(array);
    },
  },

  async mounted() {
    this.secretCode2 = this.secretCode;

    this.publishType = this.siteShot.publishType;
    this.publishDate = this.siteShot.publishDate;

    this.waterMarkType = this.siteShot.waterMarkType;
    this.waterMarkSrc = this.siteShot.waterMarkSrc;

    this.role = this.siteShot.role;

    this.setting.tags = this.siteShot.setting.tags;
    this.setting.description = this.siteShot.setting.description;

    this.setting.rate.disabled = this.siteShot.setting.rate.disabled;
    this.setting.rate.label = this.siteShot.setting.rate.label;
    this.setting.rate.ratings = this.siteShot.setting.rate.ratings.map((a) =>
      Object.assign({}, a)
    );
    // this.author.publishType = this.siteShot.author.publishType;
    // this.author.publishDate = this.siteShot.author.publishDate;
    this.author.avatar = this.siteShot.author.avatar;
    this.author.name = this.siteShot.author.name;
    this.author.link = this.siteShot.author.link;
  },

  watch: {
    // 表示時
    show: {
      async handler(val) {
        if (val) await this.onLoadAsync();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
};
</script>
<style scoped>
table.v-table tbody td {
  height: 40px;
  border: none;
}
.theme--light.v-table tbody tr:not(:last-child) {
  border-bottom: none;
}
</style>
