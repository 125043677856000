<template>
  <v-icon
    color="pink lighten-2"
    v-text="
      $application.storage.getShot(siteShot.shortCode).isFavorite
        ? `mdi-heart`
        : `mdi-heart-outline`
    "
    @click="toggleFavorite()"
  />
</template>

<script>
export default {
  name: "Shot",
  components: {},

  props: {
    siteShot: {
      type: Object,
      required: true,
    },
  },

  computed: {},

  data: () => ({}),
  methods: {
    // お気に入り情報を登録
    toggleFavorite() {
      this.$application.storage.toggleFavoriteShot(
        this.siteShot.shortCode,
        this.siteShot.thumbnailData
      );
    },
  },
  async mounted() {},
};
</script>
