<template>
  <v-list three-line>
    <v-list-item class="px-2" @click.stop="$emit('close')">
      <v-icon>mdi-chevron-right</v-icon>
      <v-list-item-title><v-icon v-text="`mdi-link`" />Links</v-list-item-title>
      <v-spacer />
    </v-list-item>
    <v-divider />

    <v-tabs v-model="selectedTab" align-with-title>
      <v-tab>
        <span><v-icon v-text="`mdi-link-box-outline`" />external links</span>
        <span>{{
          `(${
            siteShot.content.links.filter((a) => a.href.startsWith("http"))
              .length
          })`
        }}</span>
      </v-tab>
      <v-tab>
        <span><v-icon v-text="`mdi-link-box`" /> internal links</span>
        <span>{{
          `(${
            siteShot.content.links.filter((a) => !a.href.startsWith("http"))
              .length
          })`
        }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-list dense class="ml-2">
              <v-list-item
                v-for="(a, i) in siteShot.content.links.filter((a) =>
                  a.href.startsWith('http')
                )"
                :key="i"
              >
                <!-- <v-list-item-avatar tile>
        <v-img :src="img.src" />
      </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ a.text ? a.text : "-" }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <WCopyToClipBoard :value="a.href" />
                    <a
                      :href="a.href"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      {{ a.href }}
                    </a></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ a.target }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-list dense class="ml-2">
              <v-list-item
                v-for="(a, i) in siteShot.content.links.filter(
                  (a) => !a.href.startsWith('http')
                )"
                :key="i"
              >
                <!-- <v-list-item-avatar tile>
        <v-img :src="img.src" />
      </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span>{{ a.text ? a.text : "-" }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <v-tooltip bottom v-if="a.isInnerAndAbsolute">
                      <template v-slot:activator="{ on, attrs }">
                        <WCopyToClipBoard
                          :value="`${siteShot.scheme}://${siteShot.host}${a.href}`"
                        />
                        <a
                          :href="`${siteShot.scheme}://${siteShot.host}${a.href}`"
                          target="_blank"
                          class="text-decoration-none"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ a.href }}
                        </a>
                      </template>
                      {{ `${siteShot.scheme}://${siteShot.host}${a.href}` }}
                    </v-tooltip>
                    <span v-else>{{ a.href }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ a.target }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-list>
</template>

<script>
export default {
  name: "",
  components: {},

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    siteShot: {
      type: Object,
      required: true,
    },
    tab: {
      type: Number,
      default: () => 0,
    },
  },

  computed: {
    selectedTab: {
      get() {
        return this.tab;
      },
      set(val) {
        this.$emit("update:tab", val);
      },
    },
  },

  data: () => ({
    initializing: true,
  }),
  methods: {
    async onLoadAsync() {},
  },
  async mounted() {
    await this.onLoadAsync();
  },
};
</script>
