<template>
  <v-list>
    <v-list-item class="px-2" @click.stop="$emit('close')">
      <v-icon>mdi-chevron-right</v-icon>
      <v-list-item-title>Color</v-list-item-title>
      <v-spacer />
    </v-list-item>
    <v-divider />

    <v-subheader>
      <span>DominantColor</span>
      <Dialog>
        <span>test2</span>
      </Dialog>
    </v-subheader>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <PieChart
            :chart-data="dominantColorDataPieCollection"
            :options="{
              title: {
                display: true,
                text: 'カラー',
              },
              legend: {
                display: false,
              },
              scales: {},
            }"
          />

          <v-data-table
            class="elevation-0"
            :headers="[
              {
                text: 'color',
                align: 'start',
                value: 'color',
              },
              { text: 'per', value: 'value' },
            ]"
            :items="colorDatas"
            :items-per-page="-1"
          >
            <template v-slot:[`item.color`]="{ item }">
              <WCopyToClipBoard :value="item.color" />

              <v-chip
                :class="`mr-2 ${
                  $options.filters.isWhiteText(item.color)
                    ? 'white--text'
                    : 'black--text'
                }`"
                :color="item.color"
                @click.stop="
                  click();
                  selectedColor = item.color;
                "
              >
                <span>{{ item.color }}</span>
              </v-chip>
            </template>
            <template v-slot:[`item.count`]="{ item }">
              {{ item.count | number }}
            </template>
          </v-data-table>
        </v-list-item-title>
        <v-list-item-subtitle> </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import PieChart from "@/components/charts/PieChart";

export default {
  name: "",
  components: { PieChart },

  props: {
    colorDatas: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    dominantColorDataPieCollection() {
      let data = {
        labels: [],
        datasets: [
          {
            label: "",
            fill: false,
            lineTension: 0,
            borderWidth: 1,
            backgroundColor: [],
            data: [],
          },
        ],
      };
      //

      for (let i = 0; i < this.colorDatas.length; i++) {
        let colorData = this.colorDatas[i];
        data.labels.push(colorData.color);
        data.datasets[0].data.push(colorData.value);
        data.datasets[0].backgroundColor.push(colorData.color);
      }
      return data;
    },
  },

  data: () => ({
    initializing: true,
  }),
  methods: {
    async onLoadAsync() {},
  },
  async mounted() {
    await this.onLoadAsync();
  },
};
</script>
